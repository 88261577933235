import { ConfirmActionBase } from './ConfirmActionBase';

/** @deprecated This ConfirmAction shouldn't be used, use {@link ConfirmCallback } in ui/commons instead */
export class ConfirmActionWithString extends ConfirmActionBase {
	/**
	 * @param content The question to ask the user, represented as string.
	 * @param okAction The function to execute in case of a positive answer (ok).
	 * @param cancelAction The function to execute in case of a negative answer (cancel).
	 * @param okLabelText The text to use for the 'OK' button, which can make choices more clear for the user (e.g.
	 *   'Reanalyze project' instead of just 'OK').
	 * @param cancelLabelText The text to use for the 'Cancel' button.
	 * @param title The title of the dialog.
	 */
	public constructor(
		public readonly content: string,
		okAction: () => void | Promise<void>,
		cancelAction?: (() => void | Promise<void>) | null,
		okLabelText?: string,
		cancelLabelText?: string,
		title?: string
	) {
		super(okAction, cancelAction, okLabelText, cancelLabelText, title);
		this.confirmAction();
	}

	public override confirmAction(): void {
		super.confirmAction();
		const element = this.dialog!.getContentElement() as HTMLElement;
		element.innerHTML = this.content;
		element.style.maxWidth = '600px';
		this.dialog!.setVisible(true);
	}
}
