import * as events from 'ts-closure-library/lib/events/eventhandler';
import {
	ButtonSet,
	DefaultButtonCaptions,
	DefaultButtonKeys,
	DefaultButtons,
	Dialog,
	EventType
} from 'ts-closure-library/lib/ui/dialog';

/**
 * Shows an ok/cancel dialog to the user. If the question is answered with 'ok', the given action is executed. This is a
 * replacement for the browser's built-in confirm function that better matches our design.
 */
export class ConfirmActionBase {
	/** The dialog to show to the user. */
	public dialog: Dialog | null = null;

	/**
	 * @param okAction The function to execute in case of a positive answer (ok).
	 * @param cancelAction The function to execute in case of a negative answer (cancel).
	 * @param okLabelText The text to use for the 'OK' button, which can make choices more clear for the user (e.g.
	 *   'Reanalyze project' instead of just 'OK').
	 * @param cancelLabelText The text to use for the 'Cancel' button.
	 * @param title The title of the dialog.
	 */
	public constructor(
		public okAction: () => void | Promise<void>,
		public cancelAction?: (() => void | Promise<void>) | null,
		public okLabelText: string = DefaultButtonCaptions.OK,
		public cancelLabelText: string = DefaultButtonCaptions.CANCEL,
		public title: string = 'Confirmation needed'
	) {}

	/**
	 * Base method will be overwritten by child classes. Shows a confirmation dialog that executes a method based on
	 * your decision ('ok' 'cancel'). Can be customized via child classes for more complex decisions.
	 */
	public confirmAction(): void {
		this.dialog = new Dialog();
		this.dialog.setDisposeOnHide(true);
		this.dialog.setTitle(this.title);
		const buttonSet = new ButtonSet();
		buttonSet.addButton(
			{
				key: DefaultButtonKeys.OK,
				caption: this.okLabelText
			},
			true
		);
		buttonSet.addButton(
			{
				key: DefaultButtonKeys.CANCEL,
				caption: this.cancelLabelText
			},
			false,
			true
		);
		this.dialog.setButtonSet(buttonSet);
		events.listen(this.dialog, EventType.SELECT, e => {
			if (e.key === DefaultButtons.OK.key) {
				this.okAction();
			} else {
				this.cancelAction?.();
			}
		});
	}
}
